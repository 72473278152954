import React, { useContext, useEffect } from 'react'
import * as classes from './Features.module.scss'
import cn from 'classnames'
import Container from '../../../ui/Container/Container'
import SectionTitle from '../../../ui/SectionTitle/SectionTitle'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import { FormContext } from './../../../../context/index'
import splitRow from '../../../../utils/splitRow'
import { startAnimationScroll } from './../../../../utils/animations'

function Features({ data, titleSection, lang }) {
    const { animationStart } = useContext(FormContext)

    const sectionTitle = deleteHtmlTag(titleSection.value, 'p')
    const features = data.featuresText.split('\n')

    const classAnimationText = 'anime-text-projects-features'
    const classAnimationLine = 'anime-line-projects-features'

    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
        }

        const observer = startAnimationScroll([
            { type: 'text', className: classAnimationText },
            { type: 'lineGorizont', className: classAnimationLine }
        ])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    return (
        <section className={cn(classes.Section, 'js-block-dark', { [classes.LangHY]: lang === 'hy' })}>
            <Container>
                {sectionTitle ? <SectionTitle title={sectionTitle} lang={lang} light /> : null}

                {(data.featuresPhoto && data.featuresPhoto.url) || (features && features.length) ? (
                    <div className={classes.Inner}>
                        <div className={`${classes.Separator} ${classAnimationLine}`} />

                        {data.featuresPhoto && data.featuresPhoto.url ? (
                            <div className={classes.Image} style={{ backgroundImage: `url(${data.featuresPhoto.url})` }} />
                        ) : null}

                        {features && features.length ? (
                            <div className={classes.Items}>
                                {features.map((item, index) => (
                                    <div className={classes.Item} key={index}>
                                        <span className={classes.ItemNum}>{index + 1 < 10 ? `0${index + 1}` : index + 1}</span>

                                        <p className={`${classes.ItemText} ${classAnimationText} `} dangerouslySetInnerHTML={{__html: item}} />

                                        <div className={`${classes.Line} ${classAnimationLine}`} />
                                    </div>
                                ))}
                            </div>
                        ) : null}
                    </div>
                ) : null}
            </Container>
        </section>
    )
}

export default Features
