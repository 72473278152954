import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Link } from 'gatsby'
import * as classes from './NextProject.module.scss'
import cn from 'classnames'
import Container from '../../../ui/Container/Container'
import IconRight from '../../../icons/IconRight'
import { useWindowSize } from '../../../../hooks/useWindowSize'
import { getPhotoSize } from '../../../../utils/getPhotoSize'
import { deleteHtmlTag } from '../../../../utils/deleteHtmlTag'
import { gsap } from 'gsap/dist/gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { FormContext } from '../../../../context'
import splitRow from '../../../../utils/splitRow'
import { startAnimationScroll } from '../../../../utils/animations'
import splitTitleV2 from '../../../../utils/splitTitleV2'

gsap.registerPlugin(ScrollTrigger)

function NextProject({ data, title, textLocation, lang }) {
    const { animationStart } = useContext(FormContext)

    const windowSize = useWindowSize()
    const imageSize = getPhotoSize(windowSize.width)

    const titleResult = useMemo(() => deleteHtmlTag(title.value, 'p'), [])
    const previewText = useMemo(() => deleteHtmlTag(data.previewText, 'p'), [])
    const location = useMemo(() => deleteHtmlTag(textLocation, 'p'), [])

    const [titlePrint, setTitlePrint] = useState(titleResult)

    const photo = {
        1920: data?.previewPictureDesktop?.url || '',
        1440: data?.previewPictureDesktop?.url || '',
        1024: data?.previewPictureDesktop?.url || '',
        768: data?.previewPictureDesktop?.url || '',
        360: data?.previewPictureDesktop?.url || ''
    }

    const classAnimationTitle = 'anime-item-title-nextProject'
    const classAnimationText = 'anime-item-text-nextProject'
    const classAnimationLine = 'anime-item-line-nextProject'


    useEffect(() => {
        if (animationStart) {
            splitRow(classAnimationText)
            splitTitleV2(classAnimationTitle)
        }

        const observer = startAnimationScroll([
            { type: 'text', className: classAnimationText },
            { type: 'title', className: classAnimationTitle },
            { type: 'lineGorizont', className: classAnimationLine }
        ])

        return () => {
            if (observer) {
                observer.disconnect()
            }
        }
    }, [animationStart])

    useEffect(() => {
        const timer = setTimeout(() => {
            const elems = document.querySelectorAll('.nextProject-item-footer')

            elems.forEach((item, index) => {
                gsap.to(item, {
                    duration: 1.4,
                    opacity: 1,
                    ease: 'cubicBezier(0.25, 0.1, 0.25, 1)',
                    scrollTrigger: {
                        trigger: item,
                        start: 'top 80%'
                    }
                })
            })
        }, 1500)

        return () => clearTimeout(timer)
    }, [])

    return (
        <section className={`${classes.Section} js-block-dark`}>
            <Container>
                {titleResult ? (
                    <h2 className={`${classes.Title} ${classAnimationTitle}`} dangerouslySetInnerHTML={{ __html: titlePrint }} />
                ) : null}

                <div className={cn(classes.Item, { [classes.LangHY]: lang === 'hy' })}>
                    <div className={`${classes.Separator} ${classAnimationLine}`} />

                    {photo && (photo[imageSize] || photo[1440]) ? (
                        <div
                            className={classes.ItemImage}
                            style={{
                                backgroundImage: `url(${photo[imageSize] ? photo[imageSize] : photo[1440]})`
                            }}
                        />
                    ) : null}

                    <div className={classes.ItemContent}>
                        {data.title && (
                            <h3
                                className={`${classes.ItemTitle} ${classAnimationTitle} nextProject-item-title`}
                                dangerouslySetInnerHTML={{ __html: data.title }}
                            />
                        )}

                        {data.previewText && (
                            <p className={`${classes.ItemDesc} ${classAnimationText}`} dangerouslySetInnerHTML={{ __html: previewText }} />
                        )}

                        <div className={`${classes.ItemFooter} nextProject-item-footer`}>
                            {data.location && (
                                <p className={classes.ItemLocation}>
                                    {location ? location + ': ' : null}
                                    <span dangerouslySetInnerHTML={{ __html: data.location }} />
                                </p>
                            )}

                            <Link to={`${lang === 'en' ? '/en' : ''}/projects/${data.urlCode}/`}>
                                <div className={classes.ItemDetails}>
                                    <p className={classes.IconRightWrap}>
                                        <IconRight />
                                    </p>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    )
}

export default NextProject
